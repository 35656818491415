import {request} from "@/network/request";

export function userTable_o(userData){
    return request({
        params:userData,
        method:'get',
        url:'/user/selectAllUser'
    })
}

export  function getProvince(){
    return request({
        params:{},
        method:'get',
        url:'/province/selectAllProvinces'
    })
}

export  function getRole(){
    return request({
        params:{},
        method:'get',
        url:'/sysRole/selectRole'
    })
}

export function addUser(data){
   return request({
       params:data,
       method:'post',
       url:'/user/addUser'
   })
}

export function deleteUser(data){
    return request({
        params:data,
        method:'post',
        url:'/user/deleteUser'
    })
}

export function setUserEnabled(data){
    return request({
        params:data,
        method:'get',
        url:'user/setUserEnabled'
    })
}

export function advocateState(data){
    return request({
        params:data,
        method:'post',
        url:'/user/updateUserProvince'
    })
}
