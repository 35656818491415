import {request} from "@/network/request";

export  function userTable_lease(data){
    return request({
        params:data,
        method:'get',
        url:'/user/selectAllRentUsers'
    })
}

export  function getLeaseType(){
    return request({
        params:{},
        method:'get',
        url:'/roomType/selectRoomTypes'
    })
}

export function getLeaseDetails(data){
    return request({
        params:data,
        method:'get',
        url:'/rentRoom/selectRentRoomInfo'
    })
}
