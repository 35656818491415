<template>
    <div class="out_container">
        <v-snackbar
                timeout="2000"
                :value="snackbarText"
                color="#4CAF50"
                absolute
                top
                v-model="snackbarShow"
        >{{ snackbarText }}
        </v-snackbar>
        <v-card elevation="1">
            <div class="table_out">
                <!--筛选框-->
                <div class="d-flex">
                    <!--筛选-->
                    <div class="selects mr-4">
                        <div class="mr-2">区域选择</div>
                        <el-select v-model="stateValue" placeholder="全部">
                            <el-option
                                    v-for="item in stateOptions"
                                    :value="item.provinceCode"
                                    :label="item.provinceName">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="selects mr-4">
                        <div class="mr-2">房屋租赁类型</div>
                        <el-select v-model="leaseValue" placeholder="全部">
                            <el-option
                                    v-for="item in leaseOptions"
                                    :value="item.room_type_id"
                                    :label="item.room_type_name">
                            </el-option>
                        </el-select>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="#1E88E5"
                            style="font-weight: bold"
                            dark
                            class="mt-6"
                            @click="addBt">
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                        新增
                    </v-btn>
                </div>
                <!--用户表格-->
                <div class="mt-2">
                    <el-table
                            :data="tableData"
                            max-height="700"
                            style="width: 100%;height:700px;">
                        <el-table-column
                                prop="userName"
                                label="用户名"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="birthday"
                                label="出生日期">
                        </el-table-column>
                        <el-table-column
                                prop="sex"
                                label="性别">
                            <template slot-scope="scope">
                                <span>{{scope.row.sex===0?'女':'男'}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="phone"
                                label="电话">
                        </el-table-column>
                        <el-table-column
                                prop="leaseType"
                                label="房屋租赁情况">
                            <template slot-scope="scope">
                                <el-button type="primary" plain size="mini" @click="viewLeaseDetails(scope.row.userID)">
                                    查看
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop=""
                                label="账号状态">
                            <template slot-scope="scope">
                                <el-button v-if="scope.row.enabledStatus" type="primary" plain size="mini"
                                           @click="accountStatus(scope.row.userID,scope.row.enabledStatus)">正常
                                </el-button>
                                <el-button v-else="scope.row.enabledStatus" type="danger" plain size="mini"
                                           @click="accountStatus(scope.row.userID,scope.row.enabledStatus)">冻结
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="address"
                                label="操作"
                                width="220"
                        >
                            <template slot-scope="scope">
                                <!--                <el-button plain size="mini" @click="edit">编辑</el-button>-->
                                <el-button type="primary" plain size="mini" @click="leaseHouse">租赁房屋</el-button>
                                <el-button type="danger" plain size="mini" @click="Delete(scope.row.userID)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <!--表脚-->
                <div class="table_footer">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="4"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size.sync="pageSize"
                            :current-page.sync="currentPage"
                            layout="total, sizes, prev, pager, next"
                            :total="this.totalPage">
                    </el-pagination>
                </div>
            </div>
        </v-card>
        <!--弹窗-->
        <!--新增用户弹窗-->
        <v-dialog
                v-model="userDialog"
                max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ this.dialogName }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <el-form label-position="top" label-width="70px" :model="ruleForm" :rules="rules"
                                 ref="ruleForm">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="用户名" prop="name">
                                        <el-input v-model="ruleForm.name"></el-input>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="电话" prop="phone">
                                        <el-input v-model="ruleForm.phone"></el-input>
                                    </el-form-item>
                                </v-col>
                            </v-row>

                        </el-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDialog"
                    >
                        取消
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="save">
                        保存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--    查看房屋详情弹窗-->
        <v-dialog
                v-model="leaseDialog"
                max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5"> 房屋租赁详情</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <el-table
                                :data="leaseData"
                                max-height="700"
                                style="width: 100%;">
                            <el-table-column
                                    prop="address"
                                    width="400px"
                                    label="地址">
                            </el-table-column>
                            <el-table-column
                                    prop="addressType"
                                    label="租赁类型"
                            >
                            </el-table-column>
                        </el-table>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <el-button type="info" plain size="mini" @click="closeDialog">取消</el-button>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {addUser, deleteUser, getProvince, setUserEnabled} from "@/network/Details/Ordinary_admin/userManage_O";
    import {getLeaseDetails, getLeaseType, userTable_lease} from "@/network/Details/Ordinary_admin/userManage_lease";

    export default {
        name: "UserManage_lease",
        watch: {
            stateValue(newVal) {
                this.selectArea(newVal)
            },
            leaseValue(newVal) {
                this.selectRoomType(newVal)
            }
        },
        created() {
            this.updateLeaseTable()
            getProvince().then(res => {
                if (res.status === 200) {
                    this.stateOptions = res.data
                    let item = {
                        provinceCode: 0,
                        provinceName: "全部"
                    }
                    this.stateOptions.unshift(item)
                }
            })

            getLeaseType().then(res => {
                if (res.status === 200) {
                    let top = {
                        room_type_id: 0,
                        room_type_name: "全部"
                    }
                    this.leaseOptions = res.data
                    this.leaseOptions.unshift(top)
                }
            })
        },
        methods: {
            selectArea(areaCode) {
                this.areaCode = areaCode;
                this.leaseValue = 0
                if (areaCode === 0) {
                    this.updateLeaseTable();
                } else {
                    userTable_lease({
                        pageNum: this.currentPage,
                        pageSize: this.pageSize,
                        provinceCode: areaCode,
                    }).then(res => {
                        if (res.status === 200) {
                            this.tableData = res.data.info
                        }
                    })
                }
            },
            selectRoomType(roomType) {
                if (roomType === 0) {
                    this.selectArea(this.areaCode)
                } else {
                    let data = {
                        pageNum: this.currentPage,
                        pageSize: this.pageSize,
                        roomTypeID: roomType
                    }
                    if (this.areaCode !== 0) {
                        data['provinceCode'] = this.areaCode
                    }
                    userTable_lease(data).then(res => {
                        if (res.status === 200) {
                            this.tableData = res.data.info
                        }
                    })
                }
            },
            addBt() {
                this.dialogName = '新增租赁用户'
                this.userDialog = true
            },
            save() {
              this.$refs.ruleForm.validate(v=>{
                if(v){
                  addUser({
                    createdUserID: this.userId,
                    phone: this.ruleForm.phone,
                    roleID: 4,
                    userName: this.ruleForm.name,
                  }).then(res => {
                    if (res.status === 200) {
                      this.updateLeaseTable()
                      this.snackbarShow = true
                      this.snackbarText = '添加成功'

                    }
                  })
                  this.userDialog = false
                }else {
                  this.$message.error('数据有误')
                }
              })
            },
            Delete(userID) {
                this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteUser({userID: userID}).then(res => {
                        if (res.status === 200) {
                            this.updateLeaseTable()
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            edit() {
                this.dialogName = '编辑管理员'
                this.userDialog = true
            },
            closeDialog() {
                if (this.userDialog === true) {
                    this.$refs.ruleForm.resetFields();
                    this.userDialog = false
                }
                this.leaseDialog = false

            },
            accountStatus(userID, flag) {
                if (flag) {
                    this.$confirm('是否冻结账号?', '提示', {
                        confirmButtonText: '冻结',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        setUserEnabled({userID: userID, enabledStatus: 0}).then(res => {
                            if (res.status === 200) {
                                this.updateLeaseTable()
                                this.$message({
                                    type: 'success',
                                    message: '冻结成功!'
                                });
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '取消操作！'
                        });
                    });
                } else {
                    this.$confirm('是否解除冻结?', '提示', {
                        confirmButtonText: '解除',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        setUserEnabled({userID: userID, enabledStatus: 1}).then(res => {
                            if (res.status === 200) {
                                this.updateLeaseTable()
                                this.$message({
                                    type: 'success',
                                    message: '解除成功!'
                                });
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '取消操作！'
                        });
                    });
                }

            },
            viewLeaseDetails(rentID) {
                this.leaseDialog = true
                getLeaseDetails({
                    rentID: rentID
                }).then(res => {
                    this.leaseData = res.data
                })
            },
            leaseHouse() {
                this.$router.push('/houseManage')
            },
            handleSizeChange(val) {
                this.pageSize = val
            },
            handleCurrentChange(val) {
                this.currentPage = val
            },
            updateLeaseTable() {
                userTable_lease({
                    pageNum: this.currentPage,
                    pageSize: this.pageSize,
                }).then(res => {
                    if (res.status === 200) {
                        this.tableData = res.data.info
                        this.totalPage = res.data.total
                    }
                })
            }
        },
        data() {
            // 此处自定义校验手机号码js逻辑
            var phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/
            var validatePhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('号码不能为空'))
                }
                setTimeout(() => {
                    if (!phoneReg.test(value)) {
                        callback(new Error('请输入正确电话格式'))
                    } else {
                        callback()
                    }
                }, 1000)
            }
            return {
                userId: window.localStorage.getItem("userId"),
                areaCode: 0,
                snackbarText: '',
                snackbarShow: '',
                //页脚
                totalPage: 1,
                pageSize: 10,
                currentPage: 1,
                //校验表单
                ruleForm: {
                    phone: '',
                    name: '',
                    sex: '',
                    Address: '',
                    character: '',
                    date: '',
                    leaseType: '',
                },
                // 校验规则
                rules: {
                    // 校验手机号码，主要通过validator来指定验证器名称
                    phone: [{required: true, validator: validatePhone, trigger: 'blur'}],
                    name: [{required: true, message: '用户名不能为空', trigger: 'blur'}],
                    sex: [{required: true, message: '性别不能为空', trigger: 'blur'}],
                    Address: [{required: true, message: '地址不能为空', trigger: 'blur'}],
                    character: [{required: true, message: '角色不能为空', trigger: 'blur'}],
                    date: [{required: true, message: '生日不能为空', trigger: 'blur'}],
                    leaseType: [{required: true, message: '租赁类型不能为空', trigger: 'blur'}],
                },

                dialogName: '',

                //下拉框中的值
                sexValue: '',
                stateValue: '',
                characterValue: '',
                leaseValue: '',
                addBtnClick: 0,
                //弹框默认值
                deleteDialog: false,
                userDialog: false,
                leaseDialog: false,

                //下拉框选项
                stateOptions: [],
                sexOptions: [{value: '男'}, {value: '女'}],
                characterOptions: [{value: '普通管理员'}, {value: '出租人'}],
                leaseOptions: [],

                //表格数据
                tableData: [{
                    birth: '2016-05-02',
                    name: '王小虎',
                    sex: '男',
                    phone: '1345345690',
                    Address: '学成科院',
                    character: '租户',
                    leaseType: '餐饮'
                },
                    {
                        birth: '2016-05-04',
                        name: '王小虎',
                    },],
                leaseData: [],
            }
        }
    }
</script>

<style scoped lang="less">
    .out_container {
        margin-top: 1%;
        width: 98%;
        margin-left: 1%;

        .table_out {
            width: 96%;
            margin-left: 3%;

            .selects {
                padding-top: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .table_footer {
                margin-left: 65%;
                margin-top: 5px;
                padding-bottom: 5px;
            }
        }
    }
</style>
